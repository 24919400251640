import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, navigate, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ENABLE_AMPLIFY_CHATBOT } from 'gatsby-env-variables';

import { noop } from 'util/function';
import { logout } from 'state/account/account.reducers';
import { accountSendChangePasswordRequestRoutine } from 'state/account/account.routines';
import { accountIsSubmittingPasswordResetSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import AuthMobileNav from 'components/auth-mobile-nav';
import { AuthMobileNavProps } from 'components/auth-mobile-nav/auth-mobile-nav.props';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import ForgotPasswordForm, {
    ForgotPasswordFormProps
} from 'components/forgot-password-form/forgot-password-form.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

//
// --- Types ---

export interface ForgotPasswordProps extends PageProps<GatsbyTypes.ForgotPasswordDataQuery> {}

//
// --- ForgotPassword Component ---

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
    const { data } = props;

    const dispatch = useDispatch();
    const isSubmittingPasswordReset = useSelector(accountIsSubmittingPasswordResetSelector);
    const { t } = useTranslation();

    React.useEffect(() => {
        return !ENABLE_AMPLIFY_CHATBOT
            ? () => {
                  dispatch(logout());
              }
            : noop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showSendPasswordChangeSuccessModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        body={t('modals.forgotPasswordSuccess.body')}
                        icon="default"
                        title={t('modals.forgotPasswordSuccess.title')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordSuccess',
                        label: t('modals.forgotPasswordSuccess.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                showClose: true
            })
        );
    }, [dispatch, t]);

    const showSendPasswordChangeErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: <BirdiModalContentAlt subTitle={t('modals.forgotPasswordFailure.body')} />,
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordError',
                        label: t('modals.forgotPasswordFailure.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.forgotPasswordFailure.title')} icon="alert" />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const handleAuthMobileNavBackClick = React.useCallback<NonNullable<AuthMobileNavProps['onBackButtonClick']>>(() => {
        navigate('/login-issues');
    }, []);

    const handleForgotPasswordFormSubmit = React.useCallback<ForgotPasswordFormProps['onFormSubmit']>(
        (values, _formikHelpers) => {
            if (values.username) {
                dispatch(
                    accountSendChangePasswordRequestRoutine({
                        username: values.username,
                        onSuccess: () => {
                            showSendPasswordChangeSuccessModal();
                        },
                        onFailure: () => {
                            showSendPasswordChangeErrorModal();
                        }
                    })
                );
            }
        },
        [dispatch, showSendPasswordChangeErrorModal, showSendPasswordChangeSuccessModal]
    );

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            metaData={{ nodeTitle: t('forgotPassword.labels.title') }}
            useRoundedCorners={false}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('forgotPassword.labels.eyebrowText')}
                nav={<AuthMobileNav onBackButtonClick={handleAuthMobileNavBackClick} />}
                title={t('forgotPassword.labels.title')}
            >
                <ForgotPasswordForm
                    isSubmitButtonBusy={isSubmittingPasswordReset}
                    onFormSubmit={handleForgotPasswordFormSubmit}
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotPassword;

export const query = graphql`
    query ForgotPasswordData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
