import React from 'react';
import { Form, Formik, FormikConfig, useFormikContext } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { noop } from 'util/function';
import { FORGOT_PASSWORD_SCHEMA } from 'schema/forgot-password';
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

//
// --- Types ---

export interface ForgotPasswordFormValues extends Partial<{ username: string }> {}

export interface InnerFormProps {
    isSubmitButtonBusy?: boolean;
}

export interface ForgotPasswordFormProps extends InnerFormProps {
    onFormSubmit: FormikConfig<ForgotPasswordFormValues>['onSubmit'];
}

//
// --- Constants ---

const FORGOT_PASSWORD_FORM_NAME = 'ForgotPasswordForm';

//
// --- Helper Components ---

const InnerForm: React.FC<InnerFormProps> = (props) => {
    const { isSubmitButtonBusy } = props;

    const { errors, handleChange, handleSubmit, initialValues, touched, values } =
        useFormikContext<ForgotPasswordFormValues>();
    const { t } = useTranslation();

    return (
        <Form
            autoComplete="off"
            data-ga-form-name={FORGOT_PASSWORD_FORM_NAME}
            id="forgot-password-form"
            onSubmit={handleSubmit}
        >
            <Text
                defaultValue={initialValues?.username}
                errors={errors?.username ? t('forgotPassword.errors.username') : undefined}
                label={t('forgotPassword.labels.username')}
                name="username"
                onChange={handleChange}
                touched={touched?.username}
                value={values?.username}
            />
            <div className="d-flex flex-column align-items-center">
                <Button
                    className="w-100"
                    dataGAFormName={FORGOT_PASSWORD_FORM_NAME}
                    isBusy={isSubmitButtonBusy}
                    label={t('forgotPassword.labels.submit')}
                    type="submit"
                    async
                />
            </div>
        </Form>
    );
};

//
// --- ForgotPasswordForm Component ---

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
    const { isSubmitButtonBusy = false, onFormSubmit = noop } = props;

    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('forgotPassword.labels.formInstructions')}</p>
                    <Formik<ForgotPasswordFormValues>
                        initialValues={{ username: undefined }}
                        onSubmit={onFormSubmit}
                        validationSchema={FORGOT_PASSWORD_SCHEMA}
                    >
                        <InnerForm isSubmitButtonBusy={isSubmitButtonBusy} />
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPasswordForm;
